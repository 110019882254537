.content {
  @apply border text-base text-left rounded-md w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-gray-300 focus:ring-teal-500 focus:border-teal-500
}

.button {
  @apply px-4 py-2 rounded-lg inline-flex items-center hover:opacity-70 font-medium text-base
}

.save-button {
  @apply text-white bg-teal-500
}

.close-button {
  @apply bg-gray-700 mr-3 text-gray-300
}

.input-label {
  @apply block mb-2 text-lg font-medium text-gray-300
}

.icon-color {
  @apply text-gray-300
}

