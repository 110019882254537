.token-list li {
  @apply px-5 py-3 flex cursor-pointer;
}

.token-list li.focused {
  @apply bg-gray-800;
}

.token-list li.focused.dark {
  @apply bg-gray-800;
}

.token-list li.disabled {
  @apply bg-inherit opacity-50 cursor-not-allowed;
}

.token-list button:first-child {
  @apply w-[calc(100%-32px)] flex items-center mr-2 disabled:cursor-not-allowed;
}

.token-list button:first-child > div {
  @apply w-[calc(100%-52px)];
}

.token-list button:first-child > *:first-child {
  @apply h-10 w-10 mr-3;
}

.main {
  @apply flex justify-between space-x-2;
  @apply text-base font-semibold leading-normal;
}

.dark .main {
  @apply text-white;
}

.main > span:first-child {
  @apply truncate;
}

.sub {
  @apply flex justify-between space-x-2;
  @apply text-xs font-medium leading-normal text-gray-400;
}

.sub > span:first-child {
  @apply truncate;
}

.pin-button {
  @apply p-1 rounded-full hover:opacity-70 disabled:hover:opacity-100 disabled:cursor-not-allowed;
}

.pin-button svg {
  @apply h-4 w-4;
}