@tailwind base;

@layer base {
  :root {
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

html,
body {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  @apply h-full text-white bg-gray-950 break-keep antialiased;
}

#__next {
  @apply h-full;
}

@tailwind components;
@tailwind utilities;

@layer utilities {
  .animation-delay-500 {
    animation-delay: 500ms;
  }
  .animation-delay-1000 {
    animation-delay: 1s;
  }
}

.dashboard svg tspan {
  @apply stroke-gray-400;
}

.dashboard svg line {
  @apply stroke-gray-600;
}

.scrollbarNone {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.scrollbarNone::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.price-spark {
  @apply ring-1 ring-transparent;
}

.ss-tooltip-base {
  @apply !z-30 !bg-gray-700 !px-3 !py-2 !rounded-md max-w-xs;
}

.recharts-surface {
  @apply w-full h-full;
}

@layer base {
  :root {
    --radius: 0.5rem;
  }
}
