.error-message {
  @apply text-xs text-red-500 mt-1
}

.input-title {
  @apply text-xs text-gray-400 font-medium
}

.input-text {
  @apply text-[15px] font-normal
}